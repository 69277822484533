import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("filter-drawer-composer", [_c("select-filter", {
    key: "CropCode",
    attrs: {
      "label": "Crop Code",
      "reference": "grower.common.crops",
      "source": "id",
      "source-label": _vm.genCropCodeLabel
    }
  }), _c("select-filter", {
    key: "MapLevel",
    attrs: {
      "label": "Map Type",
      "reference": "grower.common.grade-map-levels",
      "source": "mapLevel",
      "source-label": "tableName"
    }
  })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "FilterGradeMap",
  methods: {
    genCropCodeLabel(option) {
      return `${option.id} - ${option.name}`;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var FilterGradeMap = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "filter-grade-map"
  }, [_c("resource", {
    attrs: {
      "name": "grower.common.crops",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.common.grade-map-levels",
      "api-url": _vm.apiUrl
    }
  }), _c("resource", {
    attrs: {
      "name": "grower.grade-maps",
      "api-url": _vm.apiUrl,
      "redirect-route": "/watties-grower/grade-maps"
    }
  }, [_c("filter-grade-map")], 1)], 1);
};
var staticRenderFns = [];
const __vue2_script = {
  components: {
    FilterGradeMap
  },
  data() {
    return {
      apiUrl: "#{VUE_APP_API_URL}#"
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
